#footer {
  background-color: #262626;
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1000;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  text-align: center;
  flex-shrink: 0;

  max-width: 100%;
  box-sizing: border-box;
}

.social-links {
  display: flex;
  gap: 28px;
  margin-left: 30px;
  margin-right: 30px;
}

.social-icon {
  width: 38px;
  height: 38px;
  cursor: pointer;
  filter: brightness(0) invert(1) sepia(0) saturate(0%) hue-rotate(0deg)
    brightness(75%);
  transition: filter 0.3s ease-in-out;
}

.social-icon:hover {
  filter: brightness(0) invert(1) sepia(0) saturate(0%) hue-rotate(0deg)
    brightness(100%);
}

.email {
  font-size: 14px;
  text-align: center;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.email-link {
  color: whitesmoke;
  text-decoration: none;
  filter: brightness(0) invert(1) sepia(0) saturate(0%) hue-rotate(0deg)
    brightness(85%);
  transition: filter 0.3s ease-in-out;
}

.email-link:hover {
  filter: brightness(0) invert(1) sepia(0) saturate(0%) hue-rotate(0deg)
    brightness(100%);
}

.copyright {
  font-size: 14px;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  #footer {
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .social-icon {
    width: 30px;
    height: 30px;
  }

  .social-links {
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .email {
    margin: 10px 0;
    position: relative;
    transform: none;
    left: auto;
  }

  .copyright {
    text-align: center;
    font-size: 12px;
    margin: 10px 0;
  }
}
