.language-selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  position: relative;
  padding-right: 10px;
}

.language-select {
  padding: 8px;
  background-color: rgba(224, 224, 224, 0.97);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.language-select:hover {
  background-color: rgba(224, 224, 224, 0.818);
}
