.scroll-to-top-btn {
  position: fixed;
  bottom: 80px;
  right: 20px;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.3s ease;
  color: rgba(16, 16, 16, 0.85);
}

.scroll-to-top-btn:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .scroll-to-top-btn {
    bottom: 10px;
    right: 10px;
    z-index: 3000;
  }
}
