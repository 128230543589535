body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #262626;
  background-image: url("../assets/s3.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: repeat-y;
  color: whitesmoke;
  font-family: "Special Elite";
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  overflow-y: visible;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  flex-grow: 1;
  padding-top: 90px;
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .main-content {
    padding-bottom: 130px;
  }
}
