/* Menu Layout */
.menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Desktop Menu Links */
.menu-links {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;
}

/* Menu Buttons */
.menu-links li button {
  background: none;
  border: none;
  color: whitesmoke;
  font-family: "Special Elite";
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.menu-links li button:hover {
  color: #b28051;
}

.menu-links a {
  display: inline-block;
  padding: 1px 6px;
  font-size: 16px;
  color: whitesmoke;
  border: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.menu-links a:hover {
  color: #b28051;
}

/* Hamburger Button (Hidden by Default) */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Mobile Menu */
@media (max-width: 768px) {
  .menu {
    justify-content: left;
  }

  .menu-toggle {
    display: block;
    padding-left: 20px;
  }

  .menu-links {
    position: fixed;
    top: 60px;
    left: -100%; /* Start off-screen */
    width: 55%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    text-align: left;
    padding: 80px 20px;
    transition: left 0.4s ease-in-out; /* Smooth slide effect */
  }

  /* Show menu when open */
  .menu-links.open {
    left: 0; /* Slide into view */
  }

  .menu-links li {
    padding: 15px 0;
  }
}
