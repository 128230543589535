#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262626;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

#logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

#logo button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  z-index: 999;
}

#logo img {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0;
  padding: 0;
}

.menu {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.language-selector {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
