#band {
  padding: 60px 20px;
  background-color: rgba(36, 36, 36, 0.637);
  color: whitesmoke;
  text-align: center;
  border-top: 6px solid rgba(16, 16, 16, 0.2);
  box-shadow: 0px -6px 10px 2px rgba(16, 16, 16, 0.5);
}

.band-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 85%;
  margin: 0 auto;
  gap: 100px;
}

.band-text {
  flex: 1;
  text-align: left;
  opacity: 1;
}

.band-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.band-text p {
  font-size: 18px;
  line-height: 1.5;
}

.band-image {
  flex: 1;
  width: 100%;
}

.band-image img {
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .band-container {
    flex-direction: column;
    text-align: center;
  }

  .band-text {
    text-align: center;
  }
}
