.videos-section {
  text-align: center;
  padding: 50px 20px;
  background-color: rgba(41, 41, 41, 0.371);
  border-top: 6px solid rgba(16, 16, 16, 0.57);
  box-shadow: 0px -6px 10px 2px rgba(16, 16, 16, 0.5);
}

.videos-section h1 {
  padding-bottom: 50px;
  font-size: 36px;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.slick-slider {
  width: 95%;
  height: fit-content;
}

.slick-slide {
  pointer-events: none;
}

.slick-current {
  pointer-events: auto;
}

.video-container {
  position: relative;
  width: 100%; /* Full width */
  max-width: 800px; /* Set a max width for desktop */
  aspect-ratio: 16 / 9; /* Maintains 16:9 aspect ratio */
  margin: auto; /* Centers it horizontally */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

@media (max-width: 768px) {
  .video-container {
    width: 90%; /* Almost full width on smaller screens */
    max-width: none;
  }
}
