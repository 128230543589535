#album-promo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 80vh;
  max-width: 90%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.title {
  font-size: 28px;
}

#album-cover-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#album-cover {
  max-width: 400px;
  height: auto;
}

#spotify-player-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#spotify-player {
  max-width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #album-promo-container {
    flex-direction: column;
    align-items: unset;
    padding-bottom: 80px;
    height: fit-content;
  }

  #album-cover {
    max-width: 350px;
  }
}

@media (max-width: 480px) {
  #album-promo-container {
    flex-direction: column;
    align-items: unset;
  }

  #album-cover {
    max-width: 80%;
  }
}

@media (max-width: 390px) {
  #album-promo-container {
    flex-direction: column;
    align-items: unset;
  }

  #album-cover {
    max-width: 80%;
  }
}
