#contact {
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  border-top: 6px solid rgba(16, 16, 16, 0.56);
  box-shadow: 0px -6px 10px 2px rgba(16, 16, 16, 0.5);
}

#contact h1 {
  font-size: 36px;
}

#contact h2 {
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 32px;
}

.contact-details p {
  font-size: 18px;
  margin: 8px 0;
}
.contact-desc {
  font-size: 18px;
}

.contact-details a {
  color: whitesmoke;
  text-decoration: none;
  font-weight: bold;
}

.contact-details a:hover {
  color: white;
}

.contact-container {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.contact-text {
  padding-top: 4px;
}
